import { TechnicalFeedbackStatus } from "@/enums/part/TechnicalFeedbackStatus";

export const technicalFeedbackIconClass = {
  [TechnicalFeedbackStatus.IN_REVIEW]: "",
  [TechnicalFeedbackStatus.PARTS_DECLINED]: "declined-icon",
  [TechnicalFeedbackStatus.PART_DECLINED]: "declined-icon",
  [TechnicalFeedbackStatus.OPEN_TECHNICAL_FEEDBACKS]: "open-icon",
  [TechnicalFeedbackStatus.NEW_UPDATES]: "updates-icon",
  [TechnicalFeedbackStatus.NO_TECHNICAL_FEEDBACKS]: "reviewed-icon",
  [TechnicalFeedbackStatus.ALL_TECHNICAL_FEEDBACKS_RESOLVED]: "reviewed-icon",
};
