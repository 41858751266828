import { AxiosResponse } from "axios";

import ApiService from "@/services";
import DiscountInterface from "@/types/quote-details/DiscountInterface";
import { DiscountStatusEnum } from "@/enums/discount/DiscountStatusEnum";

export default class DiscountService {
  static addDiscount(payload: DiscountInterface): Promise<AxiosResponse> {
    return ApiService.post("/v2/discounts", payload);
  }

  static getDiscounts(params: {
    skip: number;
    limit: number;
    id?: string;
    status?: DiscountStatusEnum;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/discounts", { params });
  }

  static changeDiscount(
    id: string,
    payload: DiscountInterface
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/discounts/${id}`, {
      payload,
    });
  }

  static addDiscountToRfq(payload: {
    discountId: string;
    rfqId: string;
  }): Promise<AxiosResponse> {
    return ApiService.post("/v2/rfq-discounts", payload);
  }

  static getRfqDiscounts(params: {
    skip: number;
    limit: number;
    rfqId?: string;
    discountId?: string;
    status?: DiscountStatusEnum;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v2/rfq-discounts", { params });
  }

  static changeRfqDiscountStatus(
    id: string,
    status: DiscountStatusEnum
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v2/rfq-discounts/${id}`, {
      status,
    });
  }

  static deleteRfqDiscount(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v2/rfq-discounts/${id}`);
  }
}
