export enum TechnicalFeedbackStatus {
  IN_REVIEW = "In review",
  PART_DECLINED = "Part declined",
  PARTS_DECLINED = "Parts declined",
  OPEN_TECHNICAL_FEEDBACKS = "Open technical feedbacks",
  NEW_UPDATES = "New updates",
  NEW_TECHNICAL_FEEDBACK_UPDATES = "New technical feedback updates",
  NO_TECHNICAL_FEEDBACKS = "No technical feedbacks",
  ALL_TECHNICAL_FEEDBACKS_RESOLVED = "All technical feedbacks resolved",
}
