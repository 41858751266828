import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "quote-info" }
const _hoisted_2 = { class: "quote-name" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = {
  key: 0,
  class: "tag-rev"
}
const _hoisted_5 = {
  key: 0,
  class: "quote-quantity"
}
const _hoisted_6 = {
  key: 0,
  class: "production-speed-modal-title"
}
const _hoisted_7 = {
  key: 1,
  class: "production-speed-modal-subtitle"
}
const _hoisted_8 = {
  key: 2,
  class: "production-speed-modal-subtitle"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "info" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "description" }
const _hoisted_13 = { class: "price" }
const _hoisted_14 = { class: "production-speed-modal-shipping-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: _normalizeClass(["production-speed-modal", {customer: $setup.isCustomerUserRole}]),
    preset: "dialog",
    "show-icon": false,
    title: $setup.isRfqAllowedAdminRole ? 'Production speed' : 'Production'
  }, {
    default: _withCtx(() => [
      ($setup.loading)
        ? (_openBlock(), _createBlock($setup["Loader"], {
            key: 0,
            loading: $setup.loading
          }, null, 8, ["loading"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString($setup.customerPN || $setup.customerRev), 1),
                ($setup.rfqPartStatus >= $setup.QuoteAdminPartStatuses.Verified)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, "REV " + _toDisplayString($setup.customerRev), 1))
                  : _createCommentVNode("", true)
              ]),
              ($setup.enabledQuantity)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Quantity: " + _toDisplayString($setup.enabledQuantity), 1))
                : _createCommentVNode("", true)
            ]),
            ($setup.isCustomerUserRole)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Choose production speed"))
              : _createCommentVNode("", true),
            ($setup.isRfqAllowedAdminRole)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Manufactured in " + _toDisplayString($setup.partProductionLocation), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($setup.partProductionLocation), 1)),
            _createVNode($setup["NRadioGroup"], {
              class: "production-speed-levels",
              value: $setup.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
              name: "radioGroup"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.productionSpeedOptions, (level) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["production-speed-levels-item", {active: $setup.value === level.id}, {customer: $setup.isCustomerUserRole}]),
                    key: level.id,
                    onClick: ($event: any) => ($setup.value = level.id)
                  }, [
                    _createVNode($setup["NRadio"], {
                      value: level.id,
                      label: level.speed
                    }, null, 8, ["value", "label"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(level.speed), 1),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("span", null, _toDisplayString(level.deliveryDate), 1),
                        ($setup.isShowShippingTooltip(level))
                          ? (_openBlock(), _createBlock($setup["ShippingDelayTooltip"], { key: 0 }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, _toDisplayString($setup.formatPrice(level.price, $setup.currency?.symbol)), 1)
                  ], 10, _hoisted_9))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"]),
            _createElementVNode("div", _hoisted_14, _toDisplayString($setup.getPartsProductionMessage), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["production-speed-modal-actions main-modal-actions", {customer: $setup.isCustomerUserRole}])
            }, [
              _createVNode($setup["NButton"], {
                class: "update-button n-button-save medium-button",
                type: "info",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onUpdateProductionSpeed()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Update")
                ]),
                _: 1
              }),
              _createVNode($setup["NButton"], {
                class: "medium-button n-button-cancel",
                type: "tertiary",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancel")
                ]),
                _: 1
              })
            ], 2)
          ], 64))
    ]),
    _: 1
  }, 8, ["title", "class"]))
}