import { ref } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import dayjs from "dayjs";
import downloadFileComposable from "@/composables/downloadFileComposable";
import { defaultDateFormatTemplate } from "@/constants/defaultDateFormatTemplate";
import { FileTypes } from "@/enums/FileTypes";
import { RouteName } from "@/enums/RouteName";
import { QuoteAdminStatuses } from "@/enums/QuoteAdminStatuses";
import QuoteService from "@/services/QuoteService";
import { fireEvent } from "@/composables/analyticsComposable";

export default () => {
  const getQuoteValidationStatusMessage = (
    quoteValidationExpiresAt: number | null | undefined
  ) => {
    const date = quoteValidationExpiresAt
      ? dayjs(quoteValidationExpiresAt)
      : null;
    if (!date) {
      return "Valid until ---";
    }
    return `${
      dayjs().isBefore(date) ? "Valid until" : "Expired on"
    } ${date.format(defaultDateFormatTemplate)}`;
  };

  const showDownload = ref(false);
  const downloadOptions = ref([
    {
      label: "Excel",
      key: "excel",
    },
    {
      label: "PDF",
      key: "pdf",
    },
  ]);

  const route = useRoute();

  const handleDownloadOptionSelect = (
    key: string,
    loading: boolean,
    quoteNumber: string,
    costingVersion: number,
    startLoader: () => void,
    finishLoader: () => void
  ) => {
    if (loading) {
      return;
    }
    startLoader();
    const fileName = getFileName(quoteNumber);
    if (key === "excel") {
      QuoteService.downloadExcelFile(route.params.id as string, costingVersion)
        .then((res) => {
          downloadFile(res.data, FileTypes.XLSX, `${fileName}.xlsx`);
          fireEvent("Quote - Downloaded quote");
        })
        .finally(() => finishLoader());
    } else if (key === "pdf") {
      QuoteService.downloadPdfFile(route.params.id as string, costingVersion)
        .then((res) => {
          downloadFile(res.data, FileTypes.PDF, `${fileName}.pdf`);
          fireEvent("Quote - Downloaded quote");
        })
        .finally(() => finishLoader());
    }
  };

  const getFileName = (quoteNumber: string, fileType = "") => {
    const fileTypeString = fileType ? ` ${fileType} ` : " ";
    return `Quote #${quoteNumber}${fileTypeString}(${
      new Date().toISOString().split("T")[0]
    })`;
  };

  const { downloadFile } = downloadFileComposable();

  const checkout = (quoteStatus: number) => {
    router.push({
      name:
        quoteStatus === QuoteAdminStatuses.Checkout ||
        quoteStatus === QuoteAdminStatuses.Quoted
          ? RouteName.Checkout
          : RouteName.CheckOutWithPurchaseOrder,
      params: { id: route.params.id as string },
    });
  };

  return {
    getQuoteValidationStatusMessage,
    showDownload,
    downloadOptions,
    handleDownloadOptionSelect,
    getFileName,
    checkout,
  };
};
