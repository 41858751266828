import { AxiosResponse } from "axios";
import ApiService from "@/services/index";

export default class SalesRegionService {
  static getSalesRegions(): Promise<AxiosResponse> {
    return ApiService.get("/v1/sales-regions");
  }
  static getCountries(params?: {
    name?: string;
    mfrRegionId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/countries/with-relations", { params });
  }
}
