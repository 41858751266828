import { createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cccac102"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amount-info" }
const _hoisted_2 = { class: "comment-to-customer" }
const _hoisted_3 = { class: "add-discount-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-discount-modal",
    preset: "dialog",
    "show-icon": false,
    title: `${$props.isEdit ? 'Edit': 'Add'} Voucher`
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createVNode($setup["NForm"], {
        class: "add-discount-form",
        ref: "formRef",
        model: $setup.form,
        rules: $setup.isDirty ? $setup.formRules : undefined
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["NFormItem"], {
              required: "",
              path: "value",
              label: "Amount"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  value: $setup.form.value,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.value) = $event)),
                  placeholder: "Enter"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode($setup["NFormItem"], {
              required: "",
              path: "currencyId",
              label: "Currency"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NSelect"], {
                  value: $setup.form.currencyId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.currencyId) = $event)),
                  options: $setup.currencyOptions,
                  placeholder: "Select currency"
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["NFormItem"], {
              required: "",
              path: "comment",
              label: "Comments to customer"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["NInput"], {
                  class: "description-textarea",
                  value: $setup.form.comment,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.comment) = $event)),
                  type: "textarea",
                  placeholder: "Enter message",
                  autosize: { minRows: 3 }
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_3, [
        ($props.isEdit)
          ? (_openBlock(), _createBlock($setup["NButton"], {
              key: 0,
              class: "update-button n-button-save medium-button",
              type: "info",
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($props.rfqId ? $setup.onAddDiscount() : $setup.onChangeDiscount()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save")
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock($setup["NButton"], {
              key: 1,
              class: "update-button n-button-save medium-button",
              type: "info",
              onClick: $setup.onAddDiscount
            }, {
              default: _withCtx(() => [
                _createTextVNode("Add")
              ]),
              _: 1
            })),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}