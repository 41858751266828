import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import CurrencyService from "@/services/CurrencyService";
import QuoteService from "@/services/QuoteService";
import CurrencyMenuInterface from "@/types/CurrencyMenuInterface";
import CurrencyInterface from "@/types/CurrencyInterface";

export default () => {
  const store = useStore();
  const currencies = ref<CurrencyMenuInterface[]>([]);
  const storedCurrencies = computed(
    () => store.getters["currencies/getCurrencies"]
  );
  const currencySign = ref<string>("USD");
  const currencyRate = ref<number>(1);

  const fetchCurrencies = (): Promise<void> | void => {
    if (storedCurrencies.value?.length) {
      setCurrencyMenu(storedCurrencies.value);
      return;
    }
    return CurrencyService.getCurrencies().then((res) => {
      store.dispatch("currencies/setCurrencies", res.data.data);
      setCurrencyMenu(res.data.data);
    });
  };

  const setCurrencyMenu = (currencyArray: CurrencyInterface[]) => {
    currencies.value = currencyArray.map((item) => ({
      label: `${item.symbol} ${item?.sign}`,
      key: `currency ${item.id}`,
      currencyId: item.id,
      id: item.id,
      symbol: item.symbol,
      currencySymbol: item.symbol,
    }));
  };

  const onUpdateQuoteCurrency = (
    option: CurrencyMenuInterface,
    quoteId: string
  ) => {
    return QuoteService.changeQuoteCurrency(
      quoteId,
      option?.currencyId || option.id
    );
  };

  const getExchangeOneRate = () => {
    CurrencyService.getExchangeOneRate({ symbol: currencySign.value }).then(
      (res) => {
        currencyRate.value = +res.data.data.rate;
      }
    );
  };
  watch(
    () => currencySign.value,
    () => {
      getExchangeOneRate();
    }
  );
  return {
    currencies,
    fetchCurrencies,
    onUpdateQuoteCurrency,
    getExchangeOneRate,
  };
};
