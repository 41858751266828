import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-203311ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "viewer-container",
  ref: "viewerContainerRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isShowUnavailableScreen)
      ? (_openBlock(), _createBlock($setup["PreviewUnavailableScreen"], { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode($setup["Loader"], {
      loading: $setup.loading && $props.showLoader
    }, null, 8, ["loading"]),
    _withDirectives(_createVNode($setup["AddMachiningDirections"], {
      shapes: $setup.shapes,
      isEditMode: $props.shapeIds.length > 0 && !$props.isNotEditable,
      isInitMode: !$props.shapeIds.length && !$props.isNotEditable,
      onSave: $setup.onSaveMachiningDirections,
      onClose: $setup.onCloseMachiningDirectionPopup
    }, null, 8, ["shapes", "isEditMode", "isInitMode"]), [
      [_vShow, $props.showAddMachiningDirectionPopup]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["part-model-viewer-modal-viewer", {grayscale: !$props.showAddMachiningDirectionPopup}]),
      ref: "modelViewerRef"
    }, null, 2)
  ], 512))
}