import CostingMachiningDirectionInterface from "@/types/cadex/CostingMachiningDirectionInterface";

export default () => {
  const formatAxis = (n: number, axis: string) => {
    return `${n < 0 ? "-" : ""}${n === 0 ? "" : axis}${
      [1, 0, -1].includes(n) ? "" : Math.abs(n).toFixed(2)
    }`;
  };

  const formatVector = (d: { x: number; y: number; z: number }) => {
    return `${formatAxis(d.x, "X")} ${formatAxis(d.y, "Y")} ${formatAxis(
      d.z,
      "Z"
    )}`.trim();
  };

  const getSelectedDirection = (
    shapes: CostingMachiningDirectionInterface[]
  ) => {
    const map = new Map();
    shapes.forEach((item) => {
      if (item?.type === "plane" && item?.normal !== undefined) {
        const normal = formatVector(item.normal);

        const existing = map.get(normal);

        if (existing) {
          existing.push(item.id);
        } else {
          map.set(normal, [item.id]);
        }
      }
    });

    return Array.from(map).map(([label, ids]) => ({
      id: ids[0],
      label: `${label} (${ids.length} face${ids.length > 1 ? "s" : ""})`,
    }));
  };

  return {
    formatAxis,
    formatVector,
    getSelectedDirection,
  };
};
