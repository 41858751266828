import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cc00dd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip--container" }
const _hoisted_2 = { class: "text-with-tooltip-wrapper" }
const _hoisted_3 = { class: "tooltip--text long" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", _hoisted_1, [
    _createVNode($setup["NTooltip"], {
      class: "tooltip--wrapper tooltip--wrapper-little",
      trigger: "hover",
      placement: "top-start",
      "show-arrow": false,
      disabled: $setup.disabledTooltip
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("p", {
            class: "text-with-tooltip",
            ref: "textRef"
          }, _toDisplayString($props.text), 513),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_3, _toDisplayString($props.text), 1)
      ]),
      _: 3
    }, 8, ["disabled"])
  ]))
}