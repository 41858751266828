import { ref, Ref } from "vue";
import { QuoteAdminStatuses } from "@/enums/QuoteAdminStatuses";
import QuoteService from "@/services/QuoteService";
import QuoteInterface from "@/types/api/QuoteInterface";

export default () => {
  const initSummaryLoading = ref(false);

  const fetchQuoteV2Summary = (quote: Ref<QuoteInterface>) => {
    initSummaryLoading.value = true;
    // We use a patch request before status Ordered because the request returns a summary and makes changes to the database.
    return (
      quote.value.status >= QuoteAdminStatuses.Ordered
        ? QuoteService.getQuoteV2Summary
        : QuoteService.updateQuoteV2Summary
    )(quote.value.id)
      .then((summary) => {
        if (!summary.data.data) {
          return;
        }
        const {
          partsProduction,
          qualityControl,
          shipping,
          totalPrice,
          prototype,
          massProduction,
          discount,
        } = summary.data.data;
        quote.value.options.totalPartProduction.price = partsProduction;
        quote.value.options.totalQualityControl.price = qualityControl;
        quote.value.options.totalShipping.price = shipping;
        quote.value.options.totalPrice = totalPrice;
        quote.value.options.discount.value = discount;
        quote.value.prototypes = prototype;
        quote.value.massProduction = massProduction;
      })
      .finally(() => {
        initSummaryLoading.value = false;
      });
  };
  return {
    initSummaryLoading,
    fetchQuoteV2Summary,
  };
};
