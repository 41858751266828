import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tooltip--container" }
const _hoisted_2 = { class: "tooltip--text long" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["NTooltip"], {
      class: _normalizeClass(["tooltip--wrapper tooltip--wrapper-little tooltip-wrapper", $props.placement]),
      trigger: "hover",
      placement: $props.placement,
      "show-arrow": false,
      disabled: $props.disabled || !$props.text
    }, {
      trigger: _withCtx(() => [
        _renderSlot(_ctx.$slots, "trigger", {}, () => [
          _createTextVNode(_toDisplayString($props.triggeredText || $props.text), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            _createTextVNode(_toDisplayString($props.text), 1)
          ])
        ])
      ]),
      _: 3
    }, 8, ["class", "placement", "disabled"])
  ]))
}