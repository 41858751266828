const tagPropertiesV2 = [
  {
    key: "material",
    value: "Material",
    order: 1,
  },
  {
    key: "subTech",
    value: "Sub-Tech",
    order: 4,
  },
  {
    key: "accuracyLevel",
    value: "Accuracy Level",
  },
  {
    key: "specialProcess",
    value: "Special Process",
  },
  {
    key: "surfaceProcesses",
    value: "Surface Processes",
    order: 0,
  },
  {
    key: "complexity",
    value: "",
    order: 3,
  },
];

export default tagPropertiesV2;
