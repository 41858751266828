import { onDeactivated, onUnmounted, ref } from "vue";
import { FileUploadProcessingStatuses } from "@/enums/FileUploadProcessingStatuses";
import FileService from "@/services/FileService";
import { ModelEntityInterface } from "@/types/ModelEntity";

const PING_INTERVAL = 3 * 1000;
const MAX_PING_TIME = 1000 * 60 * 30;

export default () => {
  const fileIds = ref<Map<string, ReturnType<typeof setInterval>>>(new Map());
  const pingImagePreview = (
    file: ModelEntityInterface
  ): Promise<ModelEntityInterface> => {
    return new Promise((resolve, reject) => {
      const getFileInterval = setInterval(() => {
        FileService.downloadFile(file.id).then((res) => {
          const { previewUploadId } = res.data.data;
          if (
            previewUploadId?.status === FileUploadProcessingStatuses.Done &&
            previewUploadId.link
          ) {
            deletePreviewInterval(file.id);
            resolve(res.data.data);
          }
        });
      }, PING_INTERVAL);
      fileIds.value.set(file.id, getFileInterval);
      setTimeout(() => {
        deletePreviewInterval(file.id);
        reject();
      }, MAX_PING_TIME);
    });
  };

  const clearIntervals = () => {
    [...fileIds.value.keys()].forEach((item) => {
      deletePreviewInterval(item);
    });
  };
  const deletePreviewInterval = (id: string) => {
    const interval = fileIds.value.get(id);
    if (interval) {
      clearInterval(interval);
      fileIds.value.delete(id);
    }
  };

  onUnmounted(() => {
    clearIntervals();
  });
  onDeactivated(() => {
    clearIntervals();
  });

  return { fileIds, pingImagePreview };
};
