import { CustomSGEVisitor, ModelAnalyzer } from "./helpers.mjs";

import cadex from "@cadexchanger/web-toolkit";

export class ModelViewer {
  /**
   * @param {HTMLElement} theContainer
   */
  constructor(theContainer) {
    // The model
    this.model = new cadex.ModelData_Model();
    // The scene for visualization
    this.scene = new cadex.ModelPrs_Scene();
    // The scene node for model visualization
    this.modelSceneNode = new cadex.ModelPrs_SceneNode();
    this.modelSceneNode.selectionMode =
      cadex.ModelPrs_SelectionMode.Body |
      cadex.ModelPrs_SelectionMode.PolyShape;
    this.scene.addRoot(this.modelSceneNode);
    this.aSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();
    this.customSGEVisitor = new CustomSGEVisitor();
    this.collectedParts = this.customSGEVisitor.collectedParts;

    // The viewport for visualization. Initializing with default config and element attach to.
    const theViewportConfig = {
      showLogo: false,
      showViewCube: true,
      cameraType: cadex.ModelPrs_CameraProjectionType.Isometric,
      autoResize: true,
    };
    this.viewport = new cadex.ModelPrs_ViewPort(
      theViewportConfig,
      theContainer
    );
    // Attach viewport to scene to render content of
    this.viewport.attachToScene(this.scene);

    this.hasBRepRep = false;
    this.polyRepCount = 0;
    this.disposed = false;
  }

  /**
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  async clear(theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    this.model.clear();
    this.modelSceneNode.removeChildNodes();
    await this.removeRootPart();
    await this.scene.update(theProgressScope);
  }

  /**
   * @param {string} theFileName
   * @param {cadex.ModelData_ExternalDataProvider} dataLoader
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  async loadModel(theFileName, dataLoader, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    const aModelLoadingProgressScope = new cadex.Base_ProgressScope(
      theProgressScope
    );
    try {
      // Load model using universal reader.
      const aModelReader = new cadex.ModelData_ModelReader(
        aModelLoadingProgressScope
      );
      if (
        !(await aModelReader.loadModel(theFileName, this.model, dataLoader))
      ) {
        throw new Error(`Failed to load and convert the file ${theFileName}`);
      }

      const aModelAnalyser = new ModelAnalyzer();
      const anUniqueVisitor =
        new cadex.ModelData_SceneGraphElementUniqueVisitor(aModelAnalyser);
      await this.model.accept(anUniqueVisitor);
      this.modelSceneNode.selectionMode = cadex.ModelPrs_SelectionMode.Face;
      this.hasBRepRep = aModelAnalyser.hasBRepRep;
      this.polyRepCount = aModelAnalyser.polyRepCount;

      const aRepMask = this.hasBRepRep
        ? cadex.ModelData_RepresentationMask.ModelData_RM_BRep
        : cadex.ModelData_RepresentationMask.ModelData_RM_Poly;

      this.customSGEVisitor.clear();
      await this.model.accept(this.customSGEVisitor);
      this.customSGEVisitor.updateRepMask(aRepMask);
    } finally {
      aModelLoadingProgressScope.close();
    }
  }

  /**
   * @param {cadex.ModelData_RepresentationMask} theRepMask
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  async displayModel(theRepMask, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    this.modelSceneNode.displayMode = this.hasBRepRep
      ? cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
      : cadex.ModelPrs_DisplayMode.Shaded;
    const aModelDisplayingProgressScope = new cadex.Base_ProgressScope(
      theProgressScope
    );
    try {
      // Create visualization graph for model.
      const aSceneNode = await this.aSceneNodeFactory.createGraphFromModel(
        this.model,
        theRepMask
      );
      if (!aSceneNode) {
        throw new Error("Failed to create scene graph from the model.");
      }

      this.modelSceneNode.addChildNode(aSceneNode);

      // Update scene to apply changes.
      await this.updateSceneSmoothly(aModelDisplayingProgressScope);

      // Finally move camera to position when the whole model is in sight
      this.viewport.fitAll();
    } finally {
      aModelDisplayingProgressScope.close();
    }
  }

  /**
   * @param {string} theFileName
   * @param {cadex.ModelData_ExternalDataProvider} dataProvider
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  async loadAndDisplayModel(theFileName, dataProvider, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    try {
      await this.clear(new cadex.Base_ProgressScope(theProgressScope, 1));
      await this.loadModel(
        theFileName,
        dataProvider,
        new cadex.Base_ProgressScope(theProgressScope, 5)
      );
      await this.displayModel(
        cadex.ModelData_RepresentationMask.ModelData_RM_Any,
        new cadex.Base_ProgressScope(theProgressScope)
      );
    } finally {
      theProgressScope.close();
    }
  }

  async removeRootPart() {
    try {
      this.scene.removeRoot(this.modelSceneNode);
      await this.scene.update();
      this.viewport.fitAll();
    } catch (theErr) {
      console.error("Unable to remove part: ", theErr);
    }
  }

  /** Add and display the part on the scene. */
  async addRootPart(part, selectionMode, displayMode) {
    try {
      this.modelSceneNode =
        this.aSceneNodeFactory.createNodeFromSceneGraphElement(part.sge);
      if (!this.modelSceneNode) {
        throw new Error("Unable to create scene node from SGE.");
      }

      this.represenationNode =
        this.aSceneNodeFactory.createNodeFromRepresentation(
          part.representation
        );
      this.modelSceneNode.addChildNode(this.represenationNode);

      this.modelSceneNode.displayMode = displayMode;
      this.modelSceneNode.selectionMode = selectionMode;
      this.modelSceneNode.visibilityMode =
        cadex.ModelPrs_VisibilityMode.Visible;
      this.modelSceneNode.appearance = new cadex.ModelData_Appearance(
        new cadex.ModelData_ColorObject(0.5, 0.5, 0.5)
      );

      this.scene.removeRoot(this.modelSceneNode);
      this.scene.addRoot(this.modelSceneNode);

      await this.scene.update();
      this.viewport.fitAll(10);
    } catch (theErr) {
      console.error("Unable to add and display part: ", theErr);
    }
  }

  /**
   * Periodically moves the camera to position when the whole model is in sight (for better user UX)
   * @param {cadex.Base_ProgressScope} [theProgressScope]
   */
  async updateSceneSmoothly(theProgressScope) {
    if (this.disposed || theProgressScope?.owner.wasCanceled()) {
      return;
    }
    // Fit all camera ~3 times per second
    let aLastBBoxChangedTime = 0;
    const onSceneBBoxChanged = () => {
      const aCurrentTime = new Date().getTime();
      if (aCurrentTime - aLastBBoxChangedTime > 300) {
        aLastBBoxChangedTime = aCurrentTime;
        this.viewport.fitAll();
      }
    };
    this.scene.addEventListener("boundingBoxChanged", onSceneBBoxChanged);

    try {
      // Update scene to apply changes.
      await this.scene.update(theProgressScope);
    } finally {
      this.scene.removeEventListener("boundingBoxChanged", onSceneBBoxChanged);
    }
  }

  dispose() {
    this.model.clear();
    this.viewport.dispose();
    this.scene.dispose();
    this.disposed = true;
  }

  objectInfo(id, metadata) {
    const shape = [...id].map((idItem) => metadata[idItem]);
    if (!shape) {
      return null;
    }
    return shape;
  }
}
