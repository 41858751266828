import cadex from "@cadexchanger/web-toolkit";
import { ModelViewer } from "./modelViewer.mjs";

class SelectedEntityVisitor extends cadex.ModelPrs_SelectedEntityVisitor {
  selectedShapes;

  visitPolyShapeEntity() {
    /* Method not implemented. */
  }

  visitPolyVertexEntity() {
    /* Method not implemented. */
  }

  constructor() {
    super();
    /** @type {Set<cadex.ModelData_Shape>} */
    this.selectedShapes = new Set();
  }

  /**
   * Fill the SelectedEntityVisitor selected shapes collection.
   * @override
   * @param {cadex.ModelPrs_SelectedShapeEntity} theShapeEntity
   */
  visitShapeEntity(theShapeEntity) {
    this.selectedShapes.add(theShapeEntity.shape);
  }
}

export class SelectionViewer extends ModelViewer {
  _selectionMode;
  _selectedPart;
  _selectedShapes;
  _allowedToSelectShapes;

  onSelectionChanged;

  constructor(
    theElement,
    onSelectionChanged,
    selectedShapes,
    isEditableSelectionMode,
    allowedToSelectShapes
  ) {
    super(theElement);

    this._selectedPart = this.collectedParts[0];
    this._selectedShapes = new Set(selectedShapes);
    this._allowedToSelectShapes = allowedToSelectShapes;

    /** @type {cadex.ModelPrs_SelectionMode} */
    this._selectionMode = isEditableSelectionMode
      ? cadex.ModelPrs_SelectionMode.Face
      : cadex.ModelPrs_SelectionMode.PolyShape;

    /* Add highlighting model shapes on scene on hover: */
    this.viewport.inputManager.isHoverEnabled = isEditableSelectionMode;
    this.viewport.inputManager.pushInputHandler(
      new cadex.ModelPrs_HighlightingHandler(this.viewport)
    );

    /* Add a handler for selection events from scene: */
    this.scene.selectionManager.addEventListener(
      "selectionChanged",
      this.onSelectionChangedByScene.bind(this)
    );

    this.preventUnselection = false;
    this.onSelectionChanged = onSelectionChanged;
    if (onSelectionChanged) {
      this.scene.selectionManager.addEventListener("selectionChanged", () => {
        if (
          this._selectionMode === cadex.ModelPrs_SelectionMode.Face &&
          !this.preventUnselection
        ) {
          onSelectionChanged(this._selectedShapes);
        } else {
          this.selectShape([...this._selectedShapes]);
        }
      });
    }
  }

  async changeSelectionMode(isEditableSelectionMode, allowedToSelectShapes) {
    this._selectionMode = isEditableSelectionMode
      ? cadex.ModelPrs_SelectionMode.Face
      : cadex.ModelPrs_SelectionMode.PolyShape;
    this.viewport.inputManager.isHoverEnabled = isEditableSelectionMode;
    this._allowedToSelectShapes = allowedToSelectShapes;
    await this.init();
  }

  updateSelectedShapes(selectedShapes) {
    this._selectedShapes = new Set(selectedShapes);
  }

  onSelectionChangedByScene(theEvent) {
    this.preventUnselection =
      theEvent.removed.length > 0 &&
      theEvent.removed?.[0]?.ca.length === this._selectedShapes.size &&
      !theEvent.added.length;
    if (
      this._selectionMode !== cadex.ModelPrs_SelectionMode.Face ||
      this.preventUnselection
    ) {
      return;
    }

    /* Get a B-Rep representation of selected part: */
    const aBrepRep =
      this._selectedPart.representation instanceof
      cadex.ModelData_BRepRepresentation
        ? this._selectedPart.representation
        : this._selectedPart.sge.brepRepresentation();
    /* Shape selection is only available for BRep representation: */
    if (!aBrepRep) {
      return;
    }

    /* For each unselected shape in the scene: */
    if (theEvent.removed.length > 0) {
      theEvent.removed.forEach((theSelectionItem) => {
        const aSelectedEntityVisitor = new SelectedEntityVisitor();
        for (const anEntity of theSelectionItem.entities()) {
          /* Fill the SelectedEntityVisitor selected shapes collection. */
          anEntity.accept(aSelectedEntityVisitor);
        }
        aSelectedEntityVisitor.selectedShapes.forEach((theSelectedShape) => {
          const aShapeID = aBrepRep.shapeId(theSelectedShape);
          /* Remove shape from the selected shapes collection: */
          this._selectedShapes.delete(aShapeID);
        });
      });
    }
    /* For each selected shape in the scene: */
    if (theEvent.added.length > 0) {
      theEvent.added.forEach((theSelectionItem) => {
        const aSelectedEntityVisitor = new SelectedEntityVisitor();
        for (const anEntity of theSelectionItem.entities()) {
          /* Fill the SelectedEntityVisitor selected shapes collection. */
          anEntity.accept(aSelectedEntityVisitor);
        }
        aSelectedEntityVisitor.selectedShapes.forEach((theSelectedShape) => {
          const aShapeID = aBrepRep.shapeId(theSelectedShape);
          /* Add shape to the selected shapes collection: */
          this._selectedShapes.add(aShapeID);
        });
      });
    }
  }

  selectShape(shapeIDs) {
    if (!this._allowedToSelectShapes) {
      return;
    }
    const aBrepRep =
      this._selectedPart.representation instanceof
      cadex.ModelData_BRepRepresentation
        ? this._selectedPart.representation
        : this._selectedPart.sge.brepRepresentation();

    /* A collection of cadex.ModelData_Shape objects associated with the collection of shape IDs. */
    const aTargetShapes = shapeIDs.map((theShapeID) => {
      const aShape = aBrepRep.shape(theShapeID);
      if (aShape) {
        return aShape;
      }
    });

    this.scene.selectionManager.deselectAll();
    aTargetShapes.forEach((shape) => {
      const aSelectionItem = new cadex.ModelPrs_SelectionItem(
        this.represenationNode,
        new cadex.ModelPrs_SelectedShapeEntity(shape)
      );
      this.scene.selectionManager.select(aSelectionItem, false, false);
    });

    this._selectedShapes.clear();
    shapeIDs.map((i) => this._selectedShapes.add(i));

    if (this.onSelectionChanged) {
      this.onSelectionChanged(this._selectedShapes);
    }
  }

  async init() {
    this._selectedPart = this.collectedParts[0];

    if (!this._selectedPart) {
      return;
    }
    this._selectedPart.sge.appearance = new cadex.ModelData_Appearance(
      new cadex.ModelData_ColorObject(0.5, 0.5, 0.5, 0.5)
    );
    await this.removeRootPart();
    await this.addRootPart(
      this._selectedPart,
      this._selectionMode,
      cadex.ModelPrs_DisplayMode.ShadedWithBoundaries
    );
    if (this._selectedShapes.size && this._allowedToSelectShapes) {
      this.selectShape.bind(this);
      this.selectShape([...this._selectedShapes].map((item) => +item));
    }

    await this.scene.update();
    this.viewport.fitAll(10);
  }
}
