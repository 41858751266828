export enum CostingEntityGroupEnum {
  GROUP_COSTINGS = "GROUP_COSTINGS",
  GROUP_COSTINGS_SUMMARIES = "GROUP_COSTINGS_SUMMARIES",
  GROUP_COSTING_RAW_MATERIALS = "GROUP_COSTING_RAW_MATERIALS",
  GROUP_COSTING_HEAT_TREATMENT_PROCESSES = "GROUP_COSTING_HEAT_TREATMENT_PROCESSES",
  GROUP_COSTING_SURFACE_PROCESSES = "GROUP_COSTING_SURFACE_PROCESSES",
  GROUP_COSTING_QC = "GROUP_COSTING_QC",
  GROUP_COSTING_BUY_ITEM_PROCESSES = "GROUP_COSTING_BUY_ITEM_PROCESSES",
  GROUP_COSTING_PRODUCTION_PROCESSES = "GROUP_COSTING_PRODUCTION_PROCESSES",
  GROUP_COSTING_PART_COMPLEXITY = "GROUP_COSTING_PART_COMPLEXITY",
  GROUP_COSTING_CLASSIFICATION = "GROUP_COSTING_CLASSIFICATION",
  GROUP_COSTING_MACHINING_DIRECTIONS = "GROUP_COSTING_MACHINING_DIRECTIONS",
}
